body * {
  transition: all 0.5s ease;
}

#wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.hero-section {
  position: relative;
  z-index: 3;
  overflow: hidden;
  background: linear-gradient(to bottom, #f5de3e 0%, #f5b03e 100%);
  border-radius: 0 0 60% 60%;
  margin: 0 -999px;
  padding: 0 999px;
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(md) {
      justify-content: left;
    }
    h1 {
      margin: 0;
      font-size: 48px;
      line-height: 1;
      @include media-breakpoint-up(md) {
        font-size: 55px;
      }
    }
  }
  h2 {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: bold;
    @include media-breakpoint-up(md) {
      font-size: 1.35rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.875rem;
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.55;
    font-weight: normal;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }
  .figure-holder {
    overflow: hidden;
    margin-bottom: 60px;
    @include media-breakpoint-up(md) {
      overflow: visible;
    }
  }
  figure {
    margin: 0 auto -37px;
    position: relative;
    max-width: 300px;

    @include media-breakpoint-up(md) {
      margin: 0 0 -110px;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 0 -70px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 0 -145px;
    }
  }
}

.btn-holder {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
    margin: 0 -20px 0 -5px;
  }
  @include media-breakpoint-up(lg) {
    margin: 0 -20px;
  }
  a {
    display: inline-block;
    vertical-align: top;
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 46%;
      margin: 0 5px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 20px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.chart-holder {
  position: relative;
  max-width: 290px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    max-width: 400px;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 30px;
  }
  .step-wrap {
    &:last-child {
      .step:after {
        display: none;
      }
    }
  }
  &:before {
    position: absolute;
    content: '';
    top: -70px;
    bottom: -999px;
    left: -999px;
    right: -999px;
    background-color: $gray-100;
    z-index: -1;

    @include media-breakpoint-up(md) {
      right: -15px;
      top: -999px;
    }
  }
}

.step {
  text-align: center;
  width: 145px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100px;
    height: 110px;

    @include media-breakpoint-up(md) {
      width: 80px;
      height: 105px;
    }

    @include media-breakpoint-up(lg) {
      width: 100px;
    }

    @include media-breakpoint-up(xl) {
      width: 150px;
      height: 150px;
    }
  }
  &.even {
    float: right;
    &:after {
      background-image: url(../../assets/arrow2.png);
      right: 85%;
      top: 28%;
      @include media-breakpoint-up(xl) {
        right: 100%;
      }
    }
  }
  &.odd {
    &:after {
      background-image: url(../../assets/arrow1.png);
      left: 85%;
      top: 25%;
      @include media-breakpoint-up(xl) {
        left: 100%;
      }
    }
  }
}

.flow-chart {
  position: relative;
  color: $gray-200;

  &:before,
  &:after {
    @include media-breakpoint-up(md) {
      position: absolute;
      content: '';
      background-image: url(../../assets/top-triangle.png);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      opacity: 0.45;
      top: -90px;
      z-index: 0;
      pointer-events: none;
      height: 320px;
      left: -50%;
      right: 0;
    }

    @include media-breakpoint-up(lg) {
      left: -32%;
    }

    @include media-breakpoint-up(xl) {
      left: -18%;
      height: 400px;
    }
  }
  &:after {
    @include media-breakpoint-up(md) {
      top: auto;
      bottom: 0;
      background-image: url(../../assets/bottom-triangle.png);
      z-index: -1;
    }
  }
}

.features-desc {
  position: relative;
  z-index: 2;
  margin-bottom: 45px;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.features-section {
  position: relative;
  z-index: 1;
  &:before,
  &:after {
    position: absolute;
    content: '';
    background-image: url(../../assets/top-triangle.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0.45;
    bottom: 777px;
    z-index: 0;
    pointer-events: none;
    height: 320px;
    left: 0;
    right: 0;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &:after {
    bottom: 0;
    background-image: url(../../assets/bottom-triangle.png);
    z-index: -1;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  figure {
    background-color: $primary;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin: 0 auto;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      height: 80px;
      width: 80px;
    }
  }
  .side-text {
    border: solid $black;
    border-width: 0 0 0 5px;
    padding-left: 35px;
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
      margin-bottom: 70px;
      padding-left: 25px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 145px;
      padding-left: 35px;
    }

    h2 {
      background-color: theme-color('primary');
      display: inline-block;
      vertical-align: top;
      padding: 5px;
      margin-left: -10px;
      font-size: 1.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 2.25rem;
      }
    }
    .d-block {
      font-family: 'gothammedium';
    }
    p {
      strong {
        font-family: 'gothammedium';
      }
    }
    &.ad {
      border: 0;
      position: relative;
      &:before {
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        position: absolute;
        content: '';
        background: linear-gradient(to bottom, #f5dc3e 0%, #f6af3f 100%);
      }
      h2 {
        background-color: transparent;
      }
    }
  }
}
.info-section {
  background: linear-gradient(to bottom, #f5de3e 0%, #f5b03e 100%);
  position: relative;
  z-index: 2;
  &:before {
    @include media-breakpoint-up(md) {
      position: absolute;
      content: '';
      background-color: $white;
      width: 65%;
      height: 100%;
      border-radius: 50%;
      z-index: -1;
      bottom: -50%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .txt-wrap {
    margin: 0 0 30px;

    @include media-breakpoint-up(md) {
      max-width: 145px;
      margin: 0 auto 30px;
      min-height: 120px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 auto 55px;
    }
  }
  strong {
    font-family: 'gothammedium';
    display: block;
  }

  figure {
    img {
      max-width: 100%;
      height: auto;
    }
    &.icon-holder {
      margin: 0 0 1.5rem;
      @include media-breakpoint-up(md) {
        margin: 0 0 1.875rem;
      }
    }
    &.phone-screen {
      margin: 0 0 72px;
      position: relative;
      @include media-breakpoint-up(md) {
        margin: 0 0 -90px;
      }
      &:before {
        position: absolute;
        content: '';
        width: 350px;
        height: 350px;
        border-radius: 50%;
        background-color: $white;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }
}
