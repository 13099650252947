// custom variables and override bootstrap variables

$white:     #fff;
$yellow:    #f5e043;
$gray-900:  #282828;
$gray-100:  #f8f8f8;
$gray-200:  #1f1f1f;
$gray-300:  #e3e3e3;
$gray-400:  #f1f1f1;

$body-bg: $white;


$gray-500:  #adb5bd !default;
$gray-600:  #868e96 !default;
$gray-700:  #495057 !default;
$gray-800:  #343a40 !default;
$black:     #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $colors);

$primary:       $yellow;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $blue;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
	5: ($spacer * 2),
	6: ($spacer * 3),
	7: ($spacer * 4.5),
	8: ($spacer * 6),
	9: ($spacer * 7.5),
	10: ($spacer * 9),
	11: ($spacer * 10.5)
), $spacers);

$base-min-width: 320px;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1340px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
);

$font-family-sans-serif:      'gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base:            $font-family-sans-serif;
              

$gotham-medium:               'gothammedium', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family:        $gotham-medium;