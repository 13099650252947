$breakpoints: (
	'xs-phone': 320px,
	'phone': 576px,
	'tablet': 768px,
	'desktop': 992px,
	'widescreen': 1200px
);

// keywords
$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤







///////////////
@import 'vendors/include-media';
@import "vendors/bootstrap/functions";
@import "vendors/bootstrap/variables";
@import "vendors/bootstrap/mixins";


@import "bootstrap-custom";

@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';

@import'layout/header';
@import 'layout/common';
@import 'layout/footer';

@import "vendors/bootstrap/root";
@import "vendors/bootstrap/reboot";
@import "vendors/bootstrap/type";
@import "vendors/bootstrap/images";
@import "vendors/bootstrap/code";
@import "vendors/bootstrap/grid";
@import "vendors/bootstrap/tables";
@import "vendors/bootstrap/forms";
@import "vendors/bootstrap/buttons";
@import "vendors/bootstrap/transitions";
@import "vendors/bootstrap/dropdown";
@import "vendors/bootstrap/button-group";
@import "vendors/bootstrap/input-group";
@import "vendors/bootstrap/custom-forms";
@import "vendors/bootstrap/nav";
@import "vendors/bootstrap/navbar";
@import "vendors/bootstrap/card";
@import "vendors/bootstrap/breadcrumb";
@import "vendors/bootstrap/pagination";
@import "vendors/bootstrap/badge";
@import "vendors/bootstrap/jumbotron";
@import "vendors/bootstrap/alert";
@import "vendors/bootstrap/progress";
@import "vendors/bootstrap/media";
@import "vendors/bootstrap/list-group";
@import "vendors/bootstrap/close";
@import "vendors/bootstrap/toasts";
@import "vendors/bootstrap/modal";
@import "vendors/bootstrap/tooltip";
@import "vendors/bootstrap/popover";
@import "vendors/bootstrap/carousel";
@import "vendors/bootstrap/spinners";
@import "vendors/bootstrap/utilities";
@import "vendors/bootstrap/print";


@font-face {
    font-family: 'gothammedium';
    src: url('../Font/gothammedium-webfont.svg') format('woff2'),
         url('../Font/gothammedium-webfont.woff') format('woff'),
         url('../Font/gothammedium-webfont.ttf') format('truetype'),
         url('../Font/gothammedium-webfont.svg#gothammedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gotham';
    src: url('../Font/gotham-light-webfont.woff2') format('woff2'),
         url('../Font/gotham-light-webfont.woff') format('woff'),
         url('../Font/gotham-light-webfont.ttf') format('truetype'),
         url('../Font/gotham-light-webfont.svg#gothamlight') format('svg');
    font-weight: normal;
    font-style: normal;

}
body {
	min-width: $base-min-width;
}
