.header {
  position: fixed;
  top:16px;
  left:0;
  right:0;
  width:100%;
  z-index: 4;
  // background: #f5dc3e;
  background: linear-gradient(to top, #f5da3f 0%,#f5de3d 100%);
  &.active {
    top: 0;
  }
}

.logo{
  color: $gray-900;
  text-decoration: none;
  font-weight: bold;
  &:hover{
    text-decoration: none;
    color:$gray-900;
  }
  img,span{
    display:inline-block;
    vertical-align:middle;
    font-style: bold;
    font-family: 'gothammedium';

  }
  img{
    margin-right:7px;
  }
}

.cta-link{
  color: $gray-900;
  font-weight: bold;
  text-decoration: none;
  font-family: 'gothammedium';

  &:hover{
    color: darken($gray-900,25%);
    text-decoration: none;
  }
}